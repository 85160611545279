import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  width: 1220px;
  margin: 0 auto;
  padding-bottom: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 40px;
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  justify-content: space-between;
  `}
`;
const P1XLogo = styled.img`
  width: 120px;
  height: 77px;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 82px;
  height: 53px;
  `}
`;

const Links = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}
`;

const MobileLinks = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`

display: flex;
`}
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const Link = styled.a`
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  margin-left: 88px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-left: 0;
  font-size: 14px;
  `}
`;

const ContactUs = styled.div`
  width: 176px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid;
  color: #00f4e5;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-image: linear-gradient(
      223deg,
      rgba(97, 232, 130, 1),
      rgba(0, 218, 204, 1),
      rgba(32, 175, 235, 1)
    )
    1 1;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 116px;
    height: 36px;
    font-size: 12px;
  `}
`;

export default function Footer() {
  return (
    <Wrapper>
      <LinksWrapper>
        <P1XLogo src={require('assets/img/logo.png')} />
        <Links>
          <Link target="_blank" href="https://link.medium.com/pUAm5ZycLxb">
            Medium
          </Link>
          <Link target="_blank" href="https://twitter.com/p1xlabs">
            Twitter
          </Link>
          <Link target="_blank" href="https://discord.com/invite/bWCRD6gKQJ">
            Discord
          </Link>
        </Links>
        {/* <ContactUs>Contact Us</ContactUs> */}
      </LinksWrapper>
      <MobileLinks>
        <Link target="_blank" href="https://link.medium.com/pUAm5ZycLxb">
          Medium
        </Link>
        <Link target="_blank" href="https://twitter.com/p1xlabs">
          Twitter
        </Link>
        <Link target="_blank" href="https://discord.com/invite/bWCRD6gKQJ">
          Discord
        </Link>
      </MobileLinks>
      <TYPE.desc fontSize={14} mobileFz={12} marginTop="40px">
        Copyrights 2025 P1X.com.All Rights Reserved.
      </TYPE.desc>
    </Wrapper>
  );
}
